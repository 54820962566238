
import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'

import breadcrumbsMixin from '~/mixins/breadcrumbs/breadcrumbsMixin'
import loginMixin from '~/mixins/auth/loginMixin'

import logo from '~/components/logo'

export default {
  components: {
    logo
  },

  mixins: [
    validationMixin,
    loginMixin
  ],

  validations: {
    form: {
      email: { required, email },
      password: { required }
    }
  },

  layout: 'empty',

  asyncData ({ $config, i18n, localePath }) {
    let breaditems = [
      {
        text: i18n.t('login.title'),
        disabled: true,
        href: $config.baseUrl + localePath({ name: 'login' })
      }
    ]
    breaditems = breadcrumbsMixin.methods.breadcrumbsItems(breaditems, localePath)

    const breadldjson = breadcrumbsMixin.methods.breadcrumbsLdJson(breaditems)

    return {
      breaditems,
      breadldjson
    }
  },

  data () {
    return {
      loading: false,

      form: {
        email: null,
        password: null
      },
      loginButtonText: '',

      showPassword: false
    }
  },

  head () {
    return {
      title: this.$t('login.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('login.desc')
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$t('login.desc')
        },
        { hid: 'og:title', name: 'og:title', content: this.$t('login.title') },
        { hid: 'og:url', name: 'og:url', content: this.$config.baseUrl + this.localePath({ name: 'login' }) }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: this.breadldjson
        }
      ]
    }
  },

  computed: {
    formInvalid () {
      return this.$v.$invalid
    }
  },

  created () {
    if (!!this.$auth.loggedIn) {
      this.$router.replace(this.localePath({ name: 'dashboard' }))
    }
    this.loginButtonText = this.$t('forms.buttons.login')
  },

  methods: {
    validateState (name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },

    onEmailEntered () {
      this.showPassword = true
    },
    async onPasswordEntered (e) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!this.loading) {
        return
      }
      e.preventDefault()
      this.loading = true

      let token = false
      try {
        this.loginButtonText = this.$t('forms.process.captcha_create')
        await this.$recaptcha.init()
        this.loginButtonText = this.$t('forms.process.captcha')
        token = await this.$recaptcha.execute('login')
        await this.$recaptcha.destroy()
        this.loginButtonText = this.$t('forms.buttons.login')
      } catch (e) {
        console.log('reCaptcha Error:', e)
        this.loginButtonText = this.$t('forms.buttons.login')
      }

      if (token) {
        try {
          const fields = this.form
          fields.rct = token
          await this.$auth.loginWith('local', { data: fields })
            .then(() => {
              // eslint-disable-next-line no-extra-boolean-cast
              if (!!this.$gtag) {
                this.$gtag('event', 'signin', { name: 'method', value: 'email' })
              }
            })
            .catch((e) => {
              this.$bvToast.toast(!!e.response && !!e.response.data && !!e.response.data.message ? e.response.data.message : (!!e.message ? e.message : e), {
                title: this.$t('toast.header.error'),
                variant: 'danger',
                solid: true
              })
            })
        } catch (e) {
          this.$bvToast.toast(e.message, {
            title: this.$t('toast.header.error'),
            variant: 'danger',
            solid: true
          })
        }
      }

      this.loading = false
    },

    onFormReset () {
      this.form = {
        email: null,
        password: null
      }
      this.showPassword = false
    }
  }
}
