const loginMixin = {
  methods: {
    async apiLoginWithEmail (fields, $axios = null) {
      $axios = $axios ?? this.$axios

      return await $axios.post('/auth/login', fields).then((res) => {
        return res?.data
      }, (err) => {
        return err?.response?.data
      })
    },

    async apiLoginLogout ($axios = null) {
      $axios = $axios ?? this.$axios

      return await $axios.post('/auth/logout').then((res) => {
        return res?.data
      }, (err) => {
        return err?.response?.data
      })
    },

    async apiLoginResetToken (fields, $axios = null) {
      $axios = $axios ?? this.$axios

      return await $axios.post('/auth/reset', fields).then((res) => {
        return res?.data
      }, (err) => {
        return err?.response?.data
      })
    },

    async apiLoginNewPassword (fields, $axios = null) {
      $axios = $axios ?? this.$axios

      return await $axios.post('/auth/new-password', fields).then((res) => {
        return res?.data
      }, (err) => {
        return err?.response?.data
      })
    }
  }
}

export default loginMixin
